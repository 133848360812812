import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class PeopleService {
  getItems(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/people?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/people?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  getPerson(id) {
    return axios.get(API_URL + `admin/people/${id}`, { headers: authHeader() });
  }

  async create(formdata) {
    const { data } = await axios.post(
      API_URL + `admin/people`,
      { ...formdata },
      {
        headers: authHeader(),
      }
    );
    return data;
  }

  save(c, bus) {
    return axios.post(API_URL + `admin/people/${c.id}`, { ...c, business_units: bus }, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + `admin/people/${id}`, { headers: authHeader() });
  }

  getBusinessUnits() {
    return axios.get(API_URL + `admin/business_units/`, { headers: authHeader() });
  }
}

export default new PeopleService();
