import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class UserService {
  
  getItems(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/users?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/users?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  getUser(id) {
    return axios.get(API_URL + `admin/users/${id}`, { headers: authHeader() });
  }

  async create({user, roles, business_units}) {
    const { data } = await axios.post(
      API_URL + `admin/users`,
      { user, roles, business_units },
      {
        headers: authHeader(),
      }
    );
    return data;
  }

  update(c, roles, bus) {
    return axios.post(API_URL + `admin/users/${c.id}`, { ...c, roles: roles, business_units: bus }, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + `admin/users/${id}`, { headers: authHeader() });
  }

  getRoles() {
    return axios.get(API_URL + `admin/roles/`, { headers: authHeader() });
  }
}

export default new UserService();
