<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Wijzig gebruiker" />

    <div class="mt-10 sm:mt-0" v-if="!loading">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              General information
            </h3>
            <p class="mt-1 text-sm text-gray-600">
              Here you can provide the information needed for the item.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <UserForm :user="data" v-if="data" @submitted='handleSave' />
          <button
            type="button"
            class="w-full inline-flex items-center px-3 py-2 mt-4 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            @click="destroy">
            Delete
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import UserService from "../../../services/admin/users/service";
import UserForm from "../../../components/admin/users/UserForm.vue";

export default {
  name: "User",
  components: {
    UserForm,
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();
    const id = route.params.id;

    function fetchData() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return UserService.getUser(id)
        .then((res) => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then((json) => {
          // set the response data
          data.value = json;
        })
        .catch((err) => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then((json) => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      fetchData();
    });

    return {
      data,
      loading,
      error,
    };
  },
  methods: {
    async myMethod() {
      const { data } = await UserService.getUser(this.$route.params.id);
      this.data = data;
    },
    async handleSave({user, roles, bus}) {
      this.saving = true;
      await UserService.update(user, roles, bus);
      this.emitter.emit('notification', {type: 'success', message: 'Updated', description: 'The user has been saved succesfully'});
      this.saving = false;
    },
    async destroy() {
      if(confirm('are you sure?')) {
        UserService.delete(this.data.id);
        this.$router.push("/users");
      }
    },
  },
};
</script>