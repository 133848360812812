<template>
  <form @submit.prevent="handleForm" method="POST">
    <div class="shadow sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <label for="title" class="block text-sm font-medium text-gray-700"
              >First name</label
            >
            <input
              type="text"
              name="first_name"
              id="first_name"
              autocomplete="first_name"
              class="
                mt-1
                focus:ring-orange-500 focus:border-orange-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
              v-model="c.first_name"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="title" class="block text-sm font-medium text-gray-700"
              >Last name</label
            >
            <input
              type="text"
              name="last_name"
              id="last_name"
              autocomplete="last_name"
              class="
                mt-1
                focus:ring-orange-500 focus:border-orange-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
              v-model="c.last_name"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="title" class="block text-sm font-medium text-gray-700">Email</label>
            <input type="text" name="email" id="email" autocomplete="email" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.email" />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="phone" class="block text-sm font-medium text-gray-700">Mobiel nummer</label>
            <input type="text" name="phone" id="phone" autocomplete="email" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.phone" />
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="title" class="block text-sm font-medium text-gray-700"
              >Password (only fill out to reset the password)</label
            >
            <input
              type="text"
              name="password"
              id="password"
              autocomplete="password"
              class="
                mt-1
                focus:ring-orange-500 focus:border-orange-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
              v-model="c.password"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="tags" class="block text-sm font-medium text-gray-700"
              >Roles</label
            >
            <Multiselect
              v-model="selectedRoles"
              mode="tags"
              :options="allRoles"
              :searchable="true"
              :classes="{
                container:
                  'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none',
                containerDisabled: 'cursor-default bg-gray-100',
                containerOpen: 'rounded-b-none',
                containerOpenTop: 'rounded-t-none',
                containerActive: 'ring ring-blue-500 ring-opacity-30',
                singleLabel:
                  'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                multipleLabel:
                  'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                search:
                  'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
                tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2',
                tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap',
                tagDisabled: 'pr-2 !bg-gray-400 text-white',
                tagRemove:
                  'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
                tagRemoveIcon:
                  'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
                tagsSearchWrapper:
                  'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
                tagsSearch:
                  'absolute inset-0 border-0 outline-none appearance-none p-0 text-base font-sans box-border w-full',
                tagsSearchCopy:
                  'invisible whitespace-pre-wrap inline-block h-px',
                placeholder:
                  'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
                caret:
                  'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
                caretOpen: 'rotate-180 pointer-events-auto',
                clear:
                  'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
                clearIcon:
                  'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                spinner:
                  'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
                dropdown:
                  'absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
                dropdownTop:
                  '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
                dropdownHidden: 'hidden',
                options: 'flex flex-col p-0 m-0 list-none',
                optionsTop: 'flex-col-reverse',
                option:
                  'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
                optionPointed: 'text-gray-800 bg-gray-100',
                optionSelected: 'text-white bg-blue-500',
                optionDisabled: 'text-gray-300 cursor-not-allowed',
                optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
                optionSelectedDisabled:
                  'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
                noOptions: 'py-2 px-3 text-gray-600 bg-white',
                noResults: 'py-2 px-3 text-gray-600 bg-white',
                fakeInput:
                  'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
                spacer: 'h-9 py-px box-content',
              }"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="tags" class="block text-sm font-medium text-gray-700">Business Unit(s)</label>
            <Multiselect
              v-model="selectedBusinessUnits"
              mode="tags"
              :options="allBusinessUnits"
              :searchable="true"
              :classes="{
                container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none',
                containerDisabled: 'cursor-default bg-gray-100',
                containerOpen: 'rounded-b-none',
                containerOpenTop: 'rounded-t-none',
                containerActive: 'ring ring-blue-500 ring-opacity-30',
                singleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                search: 'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
                tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2',
                tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap',
                tagDisabled: 'pr-2 !bg-gray-400 text-white',
                tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
                tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
                tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
                tagsSearch: 'absolute inset-0 border-0 outline-none appearance-none p-0 text-base font-sans box-border w-full',
                tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
                placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
                caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
                caretOpen: 'rotate-180 pointer-events-auto',
                clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
                clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
                dropdown: 'absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
                dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
                dropdownHidden: 'hidden',
                options: 'flex flex-col p-0 m-0 list-none',
                optionsTop: 'flex-col-reverse',
                option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
                optionPointed: 'text-gray-800 bg-gray-100',
                optionSelected: 'text-white bg-blue-500',
                optionDisabled: 'text-gray-300 cursor-not-allowed',
                optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
                optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
                noOptions: 'py-2 px-3 text-gray-600 bg-white',
                noResults: 'py-2 px-3 text-gray-600 bg-white',
                fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
                spacer: 'h-9 py-px box-content',
              }"
            />
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="internal_intro" class="block text-sm font-medium text-gray-700">Introductie medewerker</label>
            <textarea name="internal_intro" id="internal_intro" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.internal_intro" rows="10"></textarea>
          </div>

        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          type="submit"
          class="
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-orange-600
            hover:bg-orange-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-orange-500
          "
        >
          <svg
            v-show="saving"
            class="
              animate-spin
              h-4
              mr-2
              w-4
              rounded-full
              bg-transparent
              border-2 border-transparent border-opacity-50
            "
            style="border-right-color: white; border-top-color: white"
            viewBox="0 0 24 24"
          ></svg>
          {{ saving ? "saving" : "save" }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import UserService from "../../../services/admin/users/service";
import Multiselect from "@vueform/multiselect";
import PeopleService from '../../../services/admin/people/service';

export default {
  name: "PersonFormComponent",
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  components: {
    Multiselect,
  },
  setup(props) {
    const c = ref(null);
    const saving = ref(true);
    const selectedRoles = ref(true);
    const allRoles = ref(null);
    const allBusinessUnits = ref(null);
    const selectedBusinessUnits = ref(null);

    let { user } = toRefs(props);
    saving.value = false;
    c.value = user.value;
    allRoles.value = [];
    selectedBusinessUnits.value = user.value.BusinessUnits.map((t)=>{return t.id});
    selectedRoles.value = user.value.Roles.map((t) => {
      return t.id;
    });

    const fetchAllBusinessUnits = async function() {
      await PeopleService.getBusinessUnits().then(({data})=>{
        allBusinessUnits.value = data.map((t)=> {
          return {
            value: t.id,
            label: t.title
          }
        })
      });
    }

    const fetchAllRoles = async function () {
      await UserService.getRoles().then(({ data }) => {
        allRoles.value = data.map((t) => {
          return {
            value: t.id,
            label: t.name,
          };
        });
      });
    };

    onMounted(() => {
      fetchAllRoles();
      fetchAllBusinessUnits();
    });

    return {
      c,
      allRoles,
      selectedRoles,
      saving,
      allBusinessUnits,
      selectedBusinessUnits
    };
  },
  methods: {
    handleForm() {
      console.log(this.c)
      this.$emit('submitted', {user: this.c, roles: this.selectedRoles, bus: this.selectedBusinessUnits});
    }
  },
};
</script>
